import React from 'react'
import Layout from 'layouts/en'
import { UserTagState } from './index.tw'
import { Languages } from 'i18n/config'

import 'styles/base.less'

import i18n from 'i18n/en/i18n_userTag.json'

const UserTag: React.FC = (props) => {
  const demoUrl = `/api/client/user/router?demo=Retail`
  const contactUrl = '/form/parade/'
  const paradeUrl = '/form/parade/'

  return (
    <Layout {...props}>
      <UserTagState demoUrl={demoUrl} paradeUrl={paradeUrl} lang={Languages.EN} i18n={i18n} contactUrl={contactUrl} />
    </Layout>
  )
}

export default UserTag
